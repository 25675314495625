const fullText = function (keyword, text) {
    var hlen = text.length;
    var nlen = keyword.length;
    if (nlen > hlen) {
        return false;
    }
    if (nlen === hlen) {
        return keyword === text;
    }
    outer: for (var i = 0, j = 0; i < nlen; i++) {
        var nch = keyword.charCodeAt(i);
        while (j < hlen) {
            if (text.charCodeAt(j++) === nch) {
                continue outer;
            }
        }
        return false;
    }
    return true;
}

export { fullText  }