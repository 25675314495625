<template>
  <a-card :bordered="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="所属地区">
                <a-cascader :options="options"
                            :showSearch="showSearch"
                            @change="areaChange"
                            placeholder="请选择"
                            expandTrigger="hover"/>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="水源类型">
                <a-select v-model="listParam.waterTypes" placeholder="请选择" default-value="0" @change="getProjectRefresh">
                  <a-select-option value="0" :selected="listParam.waterTypes === 0">全部</a-select-option>
                  <a-select-option
                          :value="item.value"
                          :selected="item.value == listParam.waterTypes"
                          v-for="item in waterTypeMenuList"
                          :key="item.id"
                  >{{ item.description }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24" v-if="projectType === '0'">
              <a-form-item label="工程类型">
                <a-select v-model="listParam.wasuScales" placeholder="请选择" default-value="0" @change="getProjectRefresh">
                  <a-select-option value="0" :selected="listParam.wasuScales === 0">全部</a-select-option>
                  <a-select-option
                          v-for="item in ScaleTypeMenuList"
                          :value="item.value"
                          :selected="item.value == listParam.wasuScales"
                          :key="item.id"
                  >{{ item.description }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="工程名称">
                <a-input v-model="listParam.name" placeholder="" @pressEnter="getProjectRefresh(0)"/>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="检测年度">
                <a-date-picker mode="year"
                               placeholder="请选择年份"
                               format='YYYY'
                               v-model="yearSelect"
                               style="width:100%"
                               :open='yearShowOne'
                               @openChange="openChangeOne"
                               @panelChange="panelChangeOne"/>
              </a-form-item>
            </a-col>

            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="是否上报">
                <a-select v-model="listParam.updateMode"
                          placeholder="请选择"
                          @change="getProjectRefresh(0)">
                  <a-select-option value="0" >全部</a-select-option>
                  <a-select-option value="1">已上报</a-select-option>
                  <a-select-option value="2">未上报</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-table
                :columns="column"
                :dataSource="projectList"
                :pagination="pagination"
                rowKey="id"
                @change="TableChange"
        >
          <template slot="serial" slot-scope="text, record, index">
            <template>
              {{ ((pagination.current - 1)*pagination.pageSize) + (index+1) }}
            </template>
          </template>
          <template v-for="(col, i) in ['cwsName', 'cwsLoc', 'qualityNum', 'lastQualityDate']" :slot="col" slot-scope="text, record">
            <a-input
                    :key="col"
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    :placeholder="columns[i].title"
                    @change="e => handleChange(e.target.value, record.key, col)"
            />
            <template v-else>{{ text }}</template>
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click="getList(record.id, record.countryId)">报告列表</a>
            <a-divider type="vertical"  v-if="permission_edit"/>
            <a @click="newReport(record.id)" v-if="permission_edit">创建报告</a>
          </template>
        </a-table>
      </div>
    </a-card>
    <a-card title="检测报告列表" :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="报告编号">
                <a-input v-model="reportListParam.reportNo" placeholder="" @pressEnter="getReport()"/>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="日期选择">
                <a-range-picker
                        :value="createValue"
                        @change="onDateChange"
                        />
             </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="是否合格：">
                <a-select v-model="isQualified"
                          default-value="0"
                          placeholder="请选择"
                          @change="handleQualified">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="1">不合格</a-select-option>
                  <a-select-option value="2">合格</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="水样类型">
                <a-select v-model="sampleType"
                          default-value="0"
                          placeholder="请选择"
                          @change="handleSampleType">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option
                          v-for="item in SampleTypeMenuList"
                          :value="item.value"
                          :key="item.id"
                  >{{ item.description }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="8" :md="12" :sm="24">
              <a-form-item label="超标指标">
                <a-select v-model="detectId"
                          default-value="0"
                          placeholder="请选择"
                          @change="handleDetectId">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option
                          v-for="item in detectIdList"
                          :value="item.id"
                          :key="item.id"
                  >{{ item.descChinese }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="8" :md="12" :sm="24">
              <a-button type="primary" style="width:40%;" @click="getReport()" >查询报告</a-button>
              <a-button type="primary" style="width:40%;float: right" @click="exportReport()" >导出报告</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <s-table
              ref="table"
              size="default"
              rowKey="id"
              :columns="columns"
              :data="loadData"
              :pagination="reportPagination"
              :alert="false"
              showPagination="auto"
      >
        <span slot="result" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="viewReport(record.id)">查看</a>
            <a-divider type="vertical" v-if="permission_edit"/>
            <a @click="editReport(record.id)" v-if="permission_edit">编辑</a>
            <a-divider type="vertical" v-if="permission_edit"/>
            <a @click="delReport(record.id)" v-if="permission_edit">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getAreaTreeList, getMenuList ,getProjectPage} from '@/api/basic/common'
import {  getNewReport, getReportPage, getReportList, deleteReport ,getDetectList} from '@/api/quality/project'
import moment from 'moment';
import { export_json_to_excel } from '@/excel/Export2Excel'
import { fullText } from '@/utils/index'

const columns = [
  {
    title: '序号',
    dataIndex: 'index'
  },
  {
    title: '检测报告编号',
    dataIndex: 'reportNo'
  },  {
    title: '工程名称',
    dataIndex: 'projectName',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '工程水源类型',
    dataIndex: 'waterTypeName'
  },
  {
    title: '检测时间',
    dataIndex: 'detectionDate',
    sorter: true
  },
  {
    title: '地址',
    dataIndex: 'cwsLoc',
    sorter: true
  },
  {
    title: '检测结果',
    dataIndex: 'result',
    scopedSlots: { customRender: 'result' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '150px',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'error',
    value: "0",
    text: '不合格'
  },
  1: {
    status: 'success',
    value: "1",
    text: '合格'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data () {
    this.columns = columns
    this.dateFormat = 'YYYY-MM-DD'
    return {
      pagination: {
        current: 1,
        pageNo: 1,
        pageSize: 5, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ['5', '10', '20'], // 每页数量选项
        showTotal: total => `共有 ${total} 条`, // 显示总数
        onChange: (page, pageSize) => this.changePage(page, pageSize), // 点击页码事件
        total: 0 // 总条数
      },
      reportPagination: {
        current: 1,
        pageNo: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ['10', '15', '20'], // 每页数量选项
        showTotal: total => `共有 ${total} 条`, // 显示总数
        total: 0 // 总条数
      },
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      projectType: '0',
      yearShowOne: false,
      yearSelect: '2020',
      listParam: {
        areaIds: '1',
        name: '',
        natManageUnits: '',
        waterTypes: '0',
        wasuScales: '0',
        reportYear: '2020',
        updateMode: '0',
        pageSize: 5,
        pageNumber: 1
      },
      // 报告参数
      reportParam: {
        centerId: 0,
        concentrateId: '1',
        detectionDate: '2020-01-01',
        detectionName: '',
        disperseId: 0,
        flagSeason: 1,
        id: 1,
        isDelete: 0,
        projectType: 0,
        quality: 0,
        realCenterName: '',
        reportDate: '2020-01-01',
        reportNo: '',
        reportSampleEntities: [
          {}
        ],
        result: '合格',
        sampleDate: '2020-01-01',
        sampleName: '',
        status: 0,
        submitId: 0,
        taskeDetailId: 0,
        verifyName: '',
        villageAddr: '',
        villageId: 0,
        qualified: 1,
        waterTypeId: 0
      },
      reportListParam: {
        areaId: '1',
        reportNo: null,
        startDate: null,
        endDate: null,
        qualified: null,
        sampleType: null,
        projectId: null,
        detectId: null
      },
      createValue: [],
      isQualified: '0',
      sampleType: '0',
      detectId: '0',
      userAreaId: {
        id: 1
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.reportListParam)
        if(requestParameters.sampleType == 0)
          delete requestParameters.sampleType
        if(requestParameters.detectId == 0)
          delete requestParameters.detectId

        return getReportPage(requestParameters)
          .then(res => {

            for (var i = 0;i<res.data.list.length;i++) {
              res.data.list[i].index = ((parameter.pageNumber - 1)* parameter.pageSize) + i + 1
            }

            var data = {
              data: res.data.list,
              pageNo: parameter.pageNumber,
              pageSize: parameter.pageSize,
              totalCount: res.data.total,
              totalPage: parseInt((res.data.total+parameter.pageSize-1) / parameter.pageSize)
            }
            console.log(data)
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      // 返回数据
      waterTypeMenuList: [],
      ScaleTypeMenuList: [],
      SampleTypeMenuList: [],
      detectIdList: [],
      fenTypeMenuList: [],
      projectList: [],
      fenType: '0',
      options: [
        {
          value: '1',
          label: '山西省'
        }
      ],
      showSearch: {
        filter: (inputValue, path) => {
          return path.some(option => {
                    // label搜索
                    return fullText(inputValue, option.label);
                  }
          )
        },
        matchInputWidth: true
      },
      // table
      column: [
        {
          title: '#',
          dataIndex: 'serial',
          key: 'serial',
          width: '8%',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '工程名称',
          dataIndex: 'cwsName',
          key: 'cwsName',
          width: '24%',
          scopedSlots: { customRender: 'cwsName' }
        },
        {
          title: '工程位置',
          dataIndex: 'cwsLoc',
          key: 'cwsLoc',
          width: '24%',
          scopedSlots: { customRender: 'cwsLoc' }
        },
        {
          title: '年度检测次数',
          dataIndex: 'qualityNumber',
          key: 'qualityNumber',
          width: '13%',
          scopedSlots: { customRender: 'qualityNumber' }
        },
        {
          title: '最近检测时间',
          dataIndex: 'qualityDate',
          key: 'qualityDate',
          width: '13%',
          scopedSlots: { customRender: 'qualityDate' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '22%',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      if(type === "合格")
        return "success"
      else
        return "error"
    }
  },
  created () {
    let area = JSON.parse(localStorage.getItem("areaValue"));

    this.permission_edit = localStorage.getItem("permission_edit") == 'true'

    this.userAreaId.id = area.areaId
    this.listParam.areaIds = area.areaId
    this.reportListParam.areaId = area.areaId
    this.reportListParam.startDate = "2020-01-01"
    this.reportListParam.endDate = "2021-01-01"
    this.createValue=["2020-01-01","2021-01-01"]

    this.reportParam.centerId = area.depId

    // 请求接口
    this.getAreaTree()
    this.getWaterTypeMenu()
    this.getFenTypeMenu()
    this.getScaleTypeMenu()
    this.getSampleTypeMenu()
    this.getProject()
    this.getDetectId()
  },
  computed: {
  },
  methods: {
    moment,
    // 弹出日历和关闭日历的回调
    openChangeOne(status){
      //status是打开或关闭的状态
      if(status){
        this.yearShowOne =  true
      }
    },
// 得到年份选择器的值
    panelChangeOne(value){
      this.yearSelect = value
      this.listParam.reportYear = value.get('year')

      this.reportListParam.startDate = this.listParam.reportYear.toString()+"-01-01"
      this.reportListParam.endDate = (this.listParam.reportYear+1).toString()+"-01-01"

      this.yearShowOne = false
      this.createValue = [this.reportListParam.startDate,this.reportListParam.endDate]

      // this.$refs.picker.html(this.reportListParam.startDate+ ' - ' + this.reportListParam.endDate)

      this.getProject(0)
      this.$refs.table.refresh(true)
      this.getDetectId()
    },
    onDateChange(date, dateString) {
      this.createValue = date
      this.reportListParam.startDate = dateString[0]
      this.reportListParam.endDate = dateString[1]

      this.$refs.table.refresh(true)
      this.getDetectId()

    },
    handleQualified(value) {
      if(value == 0)
        this.reportListParam.qualified = null
      else
        this.reportListParam.qualified = value-1
      this.$refs.table.refresh(true)
      this.getDetectId()

    },
    handleSampleType(value) {
      if(value == 0)
        this.reportListParam.sampleType = null
      else
        this.reportListParam.sampleType = value
      this.$refs.table.refresh(true)
      this.getDetectId()

    },
    handleDetectId(value) {
      if(value == 0)
        this.reportListParam.detectId = null
      else
        this.reportListParam.detectId = value
      this.$refs.table.refresh(true)
    },
    getReport() {
      this.$refs.table.refresh(true)
      this.getDetectId()
    },
    exportReport() {
      const requestParameters = Object.assign({}, this.reportListParam)
      getReportList(requestParameters)
              .then(res => {

                for (var i = 0;i<res.data.list.length;i++) {
                  res.data.list[i].index = i + 1
                }

                const tHeader = ['序号', '报告编号', '检测供水工程', '检测时间', '检测结果', '省', '市', '县']
                const filterVal = ['index', 'reportNo', 'projectName', 'detectionDate', 'result', 'province', 'city', 'country']
                const cols = [{wpx:40},{wpx:80},{wpx:160},{wpx:80},{wpx:60},{wpx:60},{wpx:60},{wpx:60}]
                const list = res.data.list
                const data = this.formatJson(filterVal, list)
                export_json_to_excel(tHeader, data, '检测报告列表',cols)

              })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    areaChange (value) {
      this.listParam.areaIds = value[value.length - 1]
      this.getProject(0)

      this.reportListParam.projectId = null
      this.reportListParam.areaId = value[value.length - 1]
      this.$refs.table.refresh(true)
      this.getDetectId()

    },
    getProjectRefresh(value){
      this.getProject(0)
      this.$refs.table.refresh(true)
      this.getDetectId()
    },
    // 获取菜单
    getWaterTypeMenu () {
      getMenuList({ menu: '水源类型' }).then(response => {
        this.waterTypeMenuList = response.data.list
      })
    },
    getFenTypeMenu () {
      getMenuList({ menu: '分散设施' }).then(response => {
        this.fenTypeMenuList = response.data.list
        // this.fenType = response.data.list[0].value
      })
    },
    getScaleTypeMenu () {
      getMenuList({ menu: '集中设施' }).then(response => {
        this.ScaleTypeMenuList = response.data.list
      })
    },
    getSampleTypeMenu () {
      getMenuList({ menu: '水样类型' }).then(response => {
        this.SampleTypeMenuList = response.data.list
      })
    },
    getDetectId () {
      // 获取报告条件下的超标类型
      const requestParameters = Object.assign({}, this.reportListParam)
      if(requestParameters.sampleType == 0)
        delete requestParameters.sampleType
      getDetectList(requestParameters).then(response => {
        this.detectIdList = response.data.list
      })
    },
    // 供水工程请求
    getProject (number) {

      if (number === 0) {
        this.pagination.current = 1
        this.listParam.pageNumber = 1
      }

      var list = this.listParam
      if (list.waterTypes === '0') {
        delete list.waterTypes
      }

      console.log(this.listParam)

      if (list.wasuScales === '0') {
        delete list.wasuScales
      }

      getProjectPage(list).then(response => {

        this.projectList = response.data.list
        if (this.projectList) {
          this.pagination.total = response.data.total
        }

        this.reportListParam.projectId = null
      })
    },
    TableChange (e) {
      // 每页数量变更
      this.pagination.current = e.current
      this.listParam.pageNumber = e.current
      this.listParam.pageSize = e.pageSize
      this.getProject()
    },
    changePage (page, pageSize) {
      // 页码变更
      this.pagination.current = page
      this.listParam.pageNumber = page
      this.listParam.pageSize = pageSize
      this.getProject()
    },
    newReport (concentrateId) {
      this.reportParam.concentrateId = concentrateId
      getNewReport(this.reportParam).then(response => {
        var id = response.data
        this.$router.push({
          path: '/test/report/edit',
          query: {
            reportId: id
          }
        })
      })
    },
    editReport (id) {
      this.$router.push({
        path: '/test/report/edit',
        query: {
          reportId: id
        }
      })
    },
    viewReport (id) {
      this.$router.push({
        path: '/test/report/view',
        query: {
          reportId: id
        }
      })
    },
    delReport (id) {
      var self = this
      this.$confirm({
        title: '确认删除',
        content: `报告删除后无法回复，确认删除当前报告？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteReport({ id: id }).then(response => {
              self.$message.error('删除成功')
              self.$refs.table.refresh(true)
            }).catch((e) => {
              self.$message.error('删除失败')
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    getList (id, areaId) {
      this.reportListParam.projectId = id
      this.reportListParam.areaId = areaId
      this.$refs.table.refresh(true)
      this.getDetectId()

    },
    getAreaTree () {
      getAreaTreeList(this.userAreaId).then(response => {
        this.options = response.data.list
      })
    }
  }
}
</script>
