import http from "@/plugins/httpss";

const api = {
  getWord: '/api/water-quality/qualityReportList/getWord',
  add: '/api/water-quality/qualityReportList/add',
  info: '/api/water-quality/qualityReportList/getDetail',
  update: '/api/water-quality/qualityReportList/update',
  reportPage: '/api/water-quality/qualityReportList/page',
  reportList: '/api/water-quality/qualityReportList/list',
  reportDelete: '/api/water-quality/qualityReportList/delete',
  sampleAdd: '/api/water-quality/qualityReportSample/addDetail',
  sampleInfo: '/api/water-quality/qualityReportSample/getDetail',
  sampleUpdate: '/api/water-quality/qualityReportSample/updateDetail',
  sampleDelete: '/api/water-quality/qualityReportSample/deleteDetail',
  methodList: '/api/water-quality/qualityDetectionMethod/list',
  departmentList: '/api/water-quality/qualityDepartment/list',
  getDetectList: '/api/water-quality/qualityConsum/detectList'
}

export default api

export function getReportWord (parameter) {
  return http({
    url: api.getWord,
    method: 'get',
    params: parameter
  })
}

export function getNewReport (parameter) {
  return http({
    url: api.add,
    method: 'post',
    data: parameter
  })
}

export function getReportInfo (parameter) {
  return http({
    url: api.info,
    method: 'get',
    params: parameter
  })
}

export function updateReportInfo (parameter) {
  return http({
    url: api.update,
    method: 'post',
    data: parameter
  })
}

export function getReportPage (parameter) {
  return http({
    url: api.reportPage,
    method: 'get',
    params: parameter
  })
}

export function getReportList (parameter) {
  return http({
    url: api.reportList,
    method: 'get',
    params: parameter
  })
}

export function deleteReport (parameter) {
  return http({
    url: api.reportDelete,
    method: 'get',
    params: parameter
  })
}

export function addSample (parameter) {
  return http({
    url: api.sampleAdd,
    method: 'post',
    data: parameter
  })
}

export function deleteSample (parameter) {
  return http({
    url: api.sampleDelete,
    method: 'get',
    params: parameter
  })
}

export function getSampleInfo (parameter) {
  return http({
    url: api.sampleInfo,
    method: 'get',
    params: parameter
  })
}

export function updateSampleInfo (parameter) {
  return http({
    url: api.sampleUpdate,
    method: 'post',
    data: parameter
  })
}


export function getMethodList (parameter) {
  return http({
    url: api.methodList,
    method: 'get',
    params: parameter
  })
}

export function getDepartmentList (parameter) {
  return http({
    url: api.departmentList,
    method: 'get',
    params: parameter
  })
}

export function getDetectList (parameter) {
  return http({
    url: api.getDetectList,
    method: 'get',
    params: parameter
  })
}
